import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import OurDistrictFirstComponent from "../components/OurDistrictFirstComponent"
import SliderPath from "../components/SliderPath"
import Districts from "../components/Districts"
import { graphql } from "gatsby"

export default function OurDistrict(pageData) {
  const data = pageData?.data
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <div className="our-district-who-we-are">
        <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        </div>
        <OurDistrictFirstComponent data={data.wpPage} />
        <div className="path-slider-district">
          <SliderPath />
        </div>
        <Districts data={data.wpPage.OurDistrictPageFields} />
      </Layout>
    </>
  )
}

export const pageQuery = (graphql` query ourDistrictData($databaseId:Int) {
    wpPage(databaseId: {eq: $databaseId}) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        fieldGroupName
        pageDescription
      }
      OurDistrictPageFields {
        fieldGroupName
        districts {
          ... on WpDistrict {
            id
            content
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
            districtOptions {
              color
            }
            slug
            uri
            excerpt
          }
        }
      }
    }
  }
  
  `)