import React from "react"

export default function MapDistrict() {
  return (
    <>
      <div className="map-area-wrapper">
        {/* <!-- map-area start --> */}
        <div className="map-area " id="map">
          <div id="image-map-pro-container-district"></div>
        </div>
        {/* <!-- map-area end --> */}
      </div>
    </>
  )
}
