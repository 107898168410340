import React, { useContext } from "react"
import { Link } from "gatsby"
import { createMarkup, string_translation, chunk_array } from "../../../utils"
import { useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function DistrictListingView(props) {
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        explore_ar: translateString(language: AR, string: "Explore")
        explore_en: translateString(language: EN, string: "Explore")
      }
    }
  `)
  const { locale, originalUrl } = useContext(TranslateUrlsContext)

  const district = props.data.districts
  let chunked_array = chunk_array(props.data.districts, 4)

  return (
    <>
      <div className="list-wrapper">
        <div className="container">
          {chunked_array.map((districts_array, index) => (
            <div className="col-group-wrapper" key={index}>
              {districts_array.map((item, index) => (
                <div className="col-dt-3" key={index}>
                  <div className="content-wrapper">
                    <div
                      className={"title-wrapper " + item.districtOptions.color}
                    >
                      <div
                        className="title"
                        dangerouslySetInnerHTML={createMarkup(item?.title)}
                      ></div>
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={createMarkup(item?.excerpt)}
                      ></div>
                      <div className="learn-more">
                        <Link
                          to={item.uri}
                          state={{ parenturl: originalUrl }}
                          className="learn-more-btn"
                        >
                          {string_translation(
                            stringTranslations,
                            "explore",
                            locale
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="img-wrapper">
                      <img src={item?.featuredImage?.node?.localFile?.publicURL} alt= {item?.title}/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
