import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import { createMarkup, string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import MapDistrict from "../MapDistrict"
import { useStaticQuery, graphql } from "gatsby"

// import Swiper core and required modules

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"

import $ from "jquery/dist/jquery.slim"

export default function DistrictMappingView(props) {
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        explore_ar: translateString(language: AR, string: "Explore")
        explore_en: translateString(language: EN, string: "Explore")
      }
    }
  `)

  const swiperRef = React.useRef(null)
  const { locale, originalUrl } = useContext(TranslateUrlsContext)

  const handleCollapse = () => {
    $(".custom-collapsible-district .collapsible-header-district").on(
      "click",
      function () {
        const body = $(this).next(".collapsible-body-district")
        const parent = $(this).parent(".custom-collapsible-district")
        if (!$(this).hasClass("open")) {
          $(this).addClass("open")
          $(body).css("maxHeight", $(body)[0].scrollHeight + "px")
          $(this).parent().css("height", "500px")
          $(this).parent().find(".learn-more").addClass("open")
          if ($(parent).hasClass("accordian")) {
            $(parent)
              .siblings(".custom-collapsible-district")
              .each(function (index, collapsible) {
                $(collapsible)
                  .find(".collapsible-body-district")
                  .css("maxHeight", "0px")
                $(collapsible)
                  .find(".collapsible-header-district")
                  .removeClass("open")
              })
          }
          setTimeout(function () {
            swiperRef.current.swiper.update()
          }, 500)
        } else {
          $(body).css("maxHeight", "0px")
          $(this).parent().css("height", "initial")
          $(this).removeClass("open")
          $(this).parent().find(".learn-more").removeClass("open")
          setTimeout(function () {
            swiperRef.current.swiper.update()
          }, 500)
        }
      }
    )
    $(
      ".custom-collapsible-district .collapsible-header-district.open ~.collapsible-body-district"
    ).each(function (index, body) {
      $(body).css("maxHeight", body.scrollHeight + "px")
    })
  }
  useEffect(() => {
    handleCollapse()
  }, [])

  const district = props.data.districts
  return (
    <>
      <div className="container resizedcontainer">
        <div className="map-wrapper active">
          <div className="mobile-show">
            <Swiper
              id="districtswiper"
              spaceBetween={0}
              slidesPerView={"auto"}
              slidesPerGroup={1}
              freeMode={"true"}
              grabCursor={"true"}
              direction={"horizontal"}
              mousewheel={"true"}
              ref={swiperRef}
              dir={locale === "en" ? "ltr" : "rtl"}
            >
              {district.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={"district-wrapper " + item.districtOptions.color}
                  >
                    <div className="custom-collapsible-district">
                      <div className="collapsible-header-district">
                        <div
                          className="title"
                          dangerouslySetInnerHTML={createMarkup(item.title)}
                        ></div>
                      </div>
                      <div className="collapsible-body-district">
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={createMarkup(item.excerpt)}
                        ></div>
                        <div className="learn-more">
                          <Link to={item.uri} className="learn-more-btn">
                            {string_translation(
                              stringTranslations,
                              "explore",
                              locale
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="desktop-show">
            <div id="districtswiper" className="scrollable-districts">
              <div className="swiper-wrapper">
                {district.map((item) => (
                  <div className="swiper-slide">
                    <div
                      className={
                        "district-wrapper " + item.districtOptions.color
                      }
                    >
                      <div className="custom-collapsible-district">
                        <div className="collapsible-header-district">
                          <div
                            className="title"
                            dangerouslySetInnerHTML={createMarkup(item.title)}
                          ></div>
                        </div>
                        <div className="collapsible-body-district">
                          <div
                            className="desc"
                            dangerouslySetInnerHTML={createMarkup(item.excerpt)}
                          ></div>
                          <div className="learn-more">
                            <Link
                              to={item.uri}
                              state={{ parenturl: originalUrl }}
                              className="learn-more-btn"
                            >
                              {string_translation(
                                stringTranslations,
                                "explore",
                                locale
                              )}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <MapDistrict />
        </div>
      </div>
    </>
  )
}
