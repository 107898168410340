import React, { useEffect, useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import {Link} from "gatsby"

import "./districts.scss"
import DistrictListingView from "../DistrictListingView"
import DistrictMappingView from "../DistrictMappingView"



export default function Districts(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      title_ar:translateString(language: AR, string: "DISCOVER THE DISTRICTS TITLE")
      title_en:translateString(language: EN, string: "DISCOVER THE DISTRICTS TITLE")
      subtitle_ar:translateString(language: AR, string: "DISCOVER THE DISTRICTS SUBTITLE")
      subtitle_en:translateString(language: EN, string: "DISCOVER THE DISTRICTS SUBTITLE")
      listing_ar:translateString(language: AR, string: "LISTING VIEW")
      listing_en:translateString(language: EN, string: "LISTING VIEW")
      map_ar:translateString(language: AR, string: "MAP VIEW")
      map_en:translateString(language: EN, string: "MAP VIEW")
    }
  }`)


  const swiperRef = React.useRef(null)
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")
    const script3 = document.createElement("script")
    const script4 = document.createElement("script")

    script1.src = "/js/image-map-pro.min.js"
    script2.src = "/js/image-map-pro.js"
    script3.src = locale === "en" ? "/js/mapping.js" : "/js/mappingAr.js"
    script4.src = "https://kit.fontawesome.com/7749c9f08a.js"
    script1.defer = true
    script2.defer = true
    script3.defer = true
    script4.defer = true

    document.body.appendChild(script1)
    document.body.appendChild(script2)
    document.body.appendChild(script3)
    document.body.appendChild(script4)
  }, [])
  return (
    <>
      <section id="districtsMap" className="districts">
        <div className="section-title">
          <div className="container">
            <div className="small-title">{string_translation(stringTranslations,"subtitle", locale)}</div>
            <div className="big-title h2">{string_translation(stringTranslations,"title", locale)}</div>
            <div className="viewing">
              <div className="list-view">
                <div className="list-view-text h2 ">
                <Link to="">{string_translation(stringTranslations,"map", locale)}</Link>
                </div>
                <div className="map-view-text h2 active">
                  <Link to="">{string_translation(stringTranslations,"listing", locale)}</Link>
                </div>
                <div className="list-view-img">
                  <div className="switch-wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DistrictListingView {...props}/>
        <DistrictMappingView {...props}/>
      </section>
    </>
  )
}
